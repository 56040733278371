<template>
  <div>
    <template v-for="(item, index) in effectiveOptions">
      <el-tag
        :disable-transitions="true"
        :key="item.value"
        :index="index"
        :type="item.type || 'primary'"
      >
        {{ item.label }}
      </el-tag>
    </template>
  </div>
</template>

<script>
export default {
  name: "DictTag",
  props: {
    options: {
      type: Array,
      default: null,
    },
    value: [Number, String, Array]
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : [this.value];
      } else {
        return [];
      }
    },
    effectiveOptions() {
      return this.values
        .map(v => this.options.find(o => o.value === v) || {label: '未知（'+v+'）', value: v, type: 'danger'})
    }
  },
};
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>