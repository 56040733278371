var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        title: "运单轨迹",
        visible: _vm.value,
        width: "100%",
        fullscreen: true,
        "before-close": _vm.cancel,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.value = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c(
          "span",
          { staticStyle: { "font-size": "18px", "font-weight": "bold" } },
          [_vm._v("运单轨迹")]
        ),
        _c("span", { staticStyle: { "margin-left": "10px" } }, [
          _vm._v("运单号：" + _vm._s(_vm.shippingInfo.shippingNoteNumber))
        ]),
        _c("span", { staticStyle: { "margin-left": "5px" } }, [
          _vm._v("分段分单：" + _vm._s(_vm.shippingInfo.serialNumber))
        ]),
        _c("span", { staticStyle: { "margin-left": "10px", color: "red" } }, [
          _vm._v("图中红色圆圈为装货围栏，蓝色圆圈为卸货围栏")
        ])
      ]),
      _c(
        "baidu-map",
        {
          staticClass: "map",
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            "scroll-wheel-zoom": true
          },
          on: { ready: _vm.handler }
        },
        [
          _c("bm-navigation", {
            attrs: { anchor: "BMAP_ANCHOR_BOTTOM_RIGHT" }
          }),
          _vm._l(_vm.pointArr, function(point, index) {
            return _c("bm-marker", {
              key: index,
              attrs: {
                position: point,
                dragging: false,
                zIndex:
                  index === 0 ||
                  (index === _vm.pointArr.length - 1 && _vm.arrivedStatus)
                    ? 100
                    : 0,
                icon: {
                  url:
                    index === 0
                      ? require("@/img/asset/bdmap/begin.png")
                      : index === _vm.pointArr.length - 1 && _vm.arrivedStatus
                      ? require("@/img/asset/bdmap/end.png")
                      : require("@/img/asset/bdmap/point2.png"),
                  size: { width: 32, height: 32 },
                  opts: { anchor: { width: 16, height: 32 } }
                }
              },
              on: {
                click: function($event) {
                  return _vm.infoWindowOpen($event, point)
                }
              }
            })
          }),
          _c("bml-lushu", {
            attrs: {
              path: _vm.arrPois,
              icon: _vm.icon,
              play: _vm.play,
              speed: _vm.speed,
              autoView: true,
              rotation: true
            },
            on: { stop: _vm.reset1 }
          }),
          _vm._l(_vm.circlePaths, function(circlePath) {
            return _vm.circleShow
              ? _c("bm-circle", {
                  key: _vm.mapPathUpdater,
                  attrs: {
                    center: circlePath.center,
                    radius: circlePath.radius,
                    "stroke-color": circlePath.color,
                    "stroke-opacity": 0.5,
                    "stroke-weight": 2,
                    editing: true
                  },
                  on: { lineupdate: _vm.pathChanged }
                })
              : _vm._e()
          }),
          _vm._l(_vm.polygonPaths, function(polygonPath) {
            return _vm.polygonShow
              ? _c("bm-polygon", {
                  key: _vm.mapPathUpdater,
                  attrs: {
                    path: polygonPath,
                    "stroke-color": polygonPath[0].color,
                    "stroke-opacity": 0.5,
                    "stroke-weight": 3,
                    editing: true
                  },
                  on: { lineupdate: _vm.pathChanged }
                })
              : _vm._e()
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "left-content" },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "48px" } },
            [
              _c(
                "el-form-item",
                { staticClass: "compact", attrs: { label: "设备" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择设备", size: "small" },
                      on: {
                        change: function($event) {
                          return _vm.showCurrPoly(true)
                        }
                      },
                      model: {
                        value: _vm.currDevice,
                        callback: function($$v) {
                          _vm.currDevice = $$v
                        },
                        expression: "currDevice"
                      }
                    },
                    _vm._l(_vm.devices, function(dev) {
                      return _c("el-option", {
                        key: dev,
                        attrs: {
                          label:
                            dev +
                            ": 共" +
                            (
                              (_vm.trackByDevice[dev] &&
                                _vm.trackByDevice[dev].track) ||
                              []
                            ).length +
                            "轨迹点",
                          value: dev
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "速度" } },
                [
                  _c("el-slider", {
                    attrs: {
                      min: _vm.minSpeed,
                      max: _vm.maxSpeed,
                      marks: _vm.speedMarks
                    },
                    model: {
                      value: _vm.speed,
                      callback: function($$v) {
                        _vm.speed = $$v
                      },
                      expression: "speed"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.reset1 }
                    },
                    [_vm._v(_vm._s(_vm.play ? "暂停" : "启动"))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "scroller" },
            _vm._l(_vm.currTrack, function(point, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "left-item",
                  attrs: { title: "点击显示详情" },
                  on: {
                    click: function($event) {
                      return _vm.pointClick(point)
                    }
                  }
                },
                [
                  _c("p", [
                    _vm._v("时间：" + _vm._s(_vm._f("formatDate")(point.time)))
                  ]),
                  _c("p", [
                    _vm._v(
                      "经纬度：" + _vm._s(point.lng) + ", " + _vm._s(point.lat)
                    )
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }