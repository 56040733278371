var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.effectiveOptions, function(item, index) {
        return [
          _c(
            "el-tag",
            {
              key: item.value,
              attrs: {
                "disable-transitions": true,
                index: index,
                type: item.type || "primary"
              }
            },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }