import request from '@/libs/request'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

var debug = false
var id = 1000;
var cache = [{
  id: 1,
  shippingNoteNumber: 'YDH1234',
  serialNumber: '0001',
  device: 'e123',
  vehicleNumber: '浙A19203',
  createTime: 1630930965,
  shippingStatus: 1,
  driverName: '张三',
  driverPhone: '13812312344',
  uploadAddress: '北京市顺义区S330(昌金路)',
  receiptAddress: '天津市蓟州区下营镇常州村'
},{
  id: 2,
  shippingNoteNumber: 'YDH1235',
  serialNumber: '0001',
  device: 'e123',
  vehicleNumber: '京H81820',
  createTime: 1630940965,
  shippingStatus: 2,
  driverName: '里斯本',
  driverPhone: '13812334344',
  uploadAddress: '廊坊市三河市',
  receiptAddress: '地址：北京市顺义区双河大街'
},{
  id: 3,
  shippingNoteNumber: 'YDH1234',
  serialNumber: '0002',
  device: 'd123',
  vehicleNumber: '浙A19203',
  createTime: 1630930965,
  shippingStatus: 1,
  driverName: '张三',
  driverPhone: '13812312344',
  uploadAddress: '北京市顺义区S330(昌金路)',
  receiptAddress: '天津市蓟州区下营镇常州村'
},{
  id: 4,
  shippingNoteNumber: 'YDH1236',
  serialNumber: '0001',
  device: 'e123',
  vehicleNumber: '浙A19203',
  createTime: 1630930965,
  shippingStatus: 3,
  driverName: '张三',
  driverPhone: '13812312344',
  uploadAddress: '北京市顺义区S330(昌金路)',
  receiptAddress: '天津市蓟州区下营镇常州村'
},{
  id: 5,
  shippingNoteNumber: 'YDH1234',
  serialNumber: '0001',
  device: 'd234',
  vehicleNumber: '浙A19203',
  createTime: 1630930965,
  shippingStatus: 2,
  driverName: '张三',
  driverPhone: '13812312344',
  uploadAddress: '北京市顺义区S330(昌金路)',
  receiptAddress: '天津市蓟州区下营镇常州村'
}]

// 查询设备围栏关联表列表
export function listShipping(query) {
  if (debug) {
    let keys = [
      'device',
      'driverPhone',
      'shippingStatus',
      'shippingNoteNumber'
    ]
    let cached = cache
    keys.forEach(key => {
      cached = cached.filter(c => (!query[key] || c[key] === query[key]))
    })
    let start = Math.max((query.pageNum || 1) - 1, 0) * (query.pageSize || 10)
    let end = start + (query.pageSize || 10)
    return Promise.resolve({
      data:{
        count: cache.length,
        list: cached.slice(start, end)
      }
    });
  }
  return request({
    url: '/shipping_info/list',
    method: 'post',
    data: query
  })
}


export function fenceList(query) {
  return request({
    url: '/device_fence/runFenceList',
    method: 'post',
    data: query
  })
}

export function fenceDetail(query) {
  return request({
    url: '/device_fence/runFenceDetail',
    method: 'post',
    data: query
  })
}

// 导出本地电子围栏表
export function exportShippingLocal(tableEl, fileName, hideColumnIndex) {
  // hide fixed (duped) table
  var allHiddenElements = [...tableEl.querySelectorAll('.el-table__fixed table tr')];
  // hide requested hidden columns
  if (hideColumnIndex) {
    if (!Array.isArray(hideColumnIndex)) {
      hideColumnIndex = [hideColumnIndex];
    }
    hideColumnIndex.forEach(idx => {
      allHiddenElements = allHiddenElements.concat(...tableEl.querySelectorAll('table tr>:nth-child('+(idx+1)+')'));
    })
  }
  try {
    allHiddenElements.forEach(t => {
      t.style.display0 = t.style.display;
      t.style.display = 'none';
    });

    /* generate workbook object from table */
    var wb = XLSX.utils.table_to_book(tableEl, {raw: true, display: true})
    /* get binary string as output */
    var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName+'.xlsx')
    return Promise.resolve({
      code: 0,
      msg: '导出成功'
    })
  } catch(e) {
    return Promise.reject(e)
  } finally {
    allHiddenElements.forEach(t => {
      t.style.display = t.style.display0;
      delete t.style.display0;
    })
  }
}

let trackList = [{"device":"868120244912371","locateTime":"1649144528","locateType":"1","locateTypeName":"gps定位","lng":"109.900058","lat":"27.35373","address":null,"direction":"40","directionName":null,"speed":"90"},
{"device":"868120244912371","locateTime":"1649142630","locateType":"1","locateTypeName":"gps定位","lng":"109.724975","lat":"27.045498","address":null,"direction":"347","directionName":null,"speed":"88"},
{"device":"868120244912371","locateTime":"1649141660","locateType":"1","locateTypeName":"gps定位","lng":"109.753517","lat":"26.878826","address":null,"direction":"27","directionName":null,"speed":"78"},
{"device":"868120244912371","locateTime":"1649140715","locateType":"1","locateTypeName":"gps定位","lng":"109.730231","lat":"26.719569","address":null,"direction":"358","directionName":null,"speed":"68"},
{"device":"868120244912371","locateTime":"1649139771","locateType":"1","locateTypeName":"gps定位","lng":"109.719549","lat":"26.563718","address":null,"direction":"307","directionName":null,"speed":"25"},
{"device":"868120244912371","locateTime":"1649137727","locateType":"1","locateTypeName":"gps定位","lng":"109.721589","lat":"26.563574","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649137092","locateType":"2","locateTypeName":"基站定位","lng":"109.7490614","lat":"26.5335289","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649136120","locateType":"1","locateTypeName":"gps定位","lng":"109.889164","lat":"26.443979","address":null,"direction":"346","directionName":null,"speed":"80"},
{"device":"868120244912371","locateTime":"1649135176","locateType":"1","locateTypeName":"gps定位","lng":"109.891656","lat":"26.292283","address":null,"direction":"27","directionName":null,"speed":"69"},
{"device":"868120244912371","locateTime":"1649134225","locateType":"1","locateTypeName":"gps定位","lng":"109.8184","lat":"26.143946","address":null,"direction":"18","directionName":null,"speed":"78"},
{"device":"868120244912371","locateTime":"1649133280","locateType":"1","locateTypeName":"gps定位","lng":"109.777391","lat":"25.978477","address":null,"direction":"352","directionName":null,"speed":"54"},
{"device":"868120244912371","locateTime":"1649132311","locateType":"2","locateTypeName":"基站定位","lng":"109.7816523","lat":"25.8541854","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649131337","locateType":"1","locateTypeName":"gps定位","lng":"109.94516","lat":"25.78864","address":null,"direction":"15","directionName":null,"speed":"24"},
{"device":"868120244912371","locateTime":"1649130125","locateType":"1","locateTypeName":"gps定位","lng":"109.947553","lat":"25.787818","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649129815","locateType":"1","locateTypeName":"gps定位","lng":"109.947247","lat":"25.787886","address":null,"direction":"350","directionName":null,"speed":"7"},
{"device":"868120244912371","locateTime":"1649129687","locateType":"1","locateTypeName":"gps定位","lng":"109.94623","lat":"25.787654","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649129538","locateType":"1","locateTypeName":"gps定位","lng":"109.946299","lat":"25.787633","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649128586","locateType":"1","locateTypeName":"gps定位","lng":"110.064517","lat":"25.728015","address":null,"direction":"359","directionName":null,"speed":"95"},
{"device":"868120244912371","locateTime":"1649127642","locateType":"1","locateTypeName":"gps定位","lng":"110.054179","lat":"25.576817","address":null,"direction":"338","directionName":null,"speed":"42"},
{"device":"868120244912371","locateTime":"1649126687","locateType":"1","locateTypeName":"gps定位","lng":"110.096429","lat":"25.409785","address":null,"direction":"339","directionName":null,"speed":"65"},
{"device":"868120244912371","locateTime":"1649125733","locateType":"1","locateTypeName":"gps定位","lng":"110.141151","lat":"25.258895","address":null,"direction":"39","directionName":null,"speed":"78"},
{"device":"868120244912371","locateTime":"1649124778","locateType":"1","locateTypeName":"gps定位","lng":"110.222831","lat":"25.119274","address":null,"direction":"289","directionName":null,"speed":"87"},
{"device":"868120244912371","locateTime":"1649123830","locateType":"1","locateTypeName":"gps定位","lng":"110.320113","lat":"24.93589","address":null,"direction":"332","directionName":null,"speed":"89"},
{"device":"868120244912371","locateTime":"1649122879","locateType":"1","locateTypeName":"gps定位","lng":"110.419502","lat":"24.764908","address":null,"direction":"322","directionName":null,"speed":"84"},
{"device":"868120244912371","locateTime":"1649121936","locateType":"1","locateTypeName":"gps定位","lng":"110.578361","lat":"24.678578","address":null,"direction":"310","directionName":null,"speed":"70"},
{"device":"868120244912371","locateTime":"1649120985","locateType":"1","locateTypeName":"gps定位","lng":"110.775853","lat":"24.646219","address":null,"direction":"271","directionName":null,"speed":"86"},
{"device":"868120244912371","locateTime":"1649120015","locateType":"1","locateTypeName":"gps定位","lng":"110.955778","lat":"24.562885","address":null,"direction":"288","directionName":null,"speed":"96"},
{"device":"868120244912371","locateTime":"1649118827","locateType":"1","locateTypeName":"gps定位","lng":"111.15086","lat":"24.414721","address":null,"direction":"262","directionName":null,"speed":"76"},
{"device":"868120244912371","locateTime":"1649117863","locateType":"1","locateTypeName":"gps定位","lng":"111.340686","lat":"24.381092","address":null,"direction":"291","directionName":null,"speed":"90"},
{"device":"868120244912371","locateTime":"1649116892","locateType":"1","locateTypeName":"gps定位","lng":"111.539726","lat":"24.363732","address":null,"direction":"256","directionName":null,"speed":"86"},
{"device":"868120244912371","locateTime":"1649106564","locateType":"1","locateTypeName":"gps定位","lng":"111.645996","lat":"24.349567","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649095711","locateType":"1","locateTypeName":"gps定位","lng":"111.645996","lat":"24.349567","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649095531","locateType":"1","locateTypeName":"gps定位","lng":"111.645965","lat":"24.349556","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649094563","locateType":"1","locateTypeName":"gps定位","lng":"111.674656","lat":"24.219149","address":null,"direction":"325","directionName":null,"speed":"75"},
{"device":"868120244912371","locateTime":"1649093603","locateType":"1","locateTypeName":"gps定位","lng":"111.731685","lat":"24.058897","address":null,"direction":"323","directionName":null,"speed":"64"},
{"device":"868120244912371","locateTime":"1649092629","locateType":"1","locateTypeName":"gps定位","lng":"111.903908","lat":"23.985583","address":null,"direction":"322","directionName":null,"speed":"60"},
{"device":"868120244912371","locateTime":"1649091672","locateType":"1","locateTypeName":"gps定位","lng":"112.104012","lat":"23.959647","address":null,"direction":"261","directionName":null,"speed":"55"},
{"device":"868120244912371","locateTime":"1649090710","locateType":"1","locateTypeName":"gps定位","lng":"112.217388","lat":"23.814703","address":null,"direction":"291","directionName":null,"speed":"67"},
{"device":"868120244912371","locateTime":"1649089756","locateType":"1","locateTypeName":"gps定位","lng":"112.266562","lat":"23.659657","address":null,"direction":"336","directionName":null,"speed":"63"},
{"device":"868120244912371","locateTime":"1649088803","locateType":"1","locateTypeName":"gps定位","lng":"112.410067","lat":"23.552136","address":null,"direction":"291","directionName":null,"speed":"82"},
{"device":"868120244912371","locateTime":"1649166563","locateType":"1","locateTypeName":"gps定位","lng":"108.390249","lat":"29.476187","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649166056","locateType":"2","locateTypeName":"基站定位","lng":"108.4305344","lat":"29.4860522","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649165091","locateType":"2","locateTypeName":"基站定位","lng":"108.6156741","lat":"29.5052108","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649163981","locateType":"2","locateTypeName":"基站定位","lng":"108.7772938","lat":"29.4433176","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649163005","locateType":"1","locateTypeName":"gps定位","lng":"108.767138","lat":"29.303981","address":null,"direction":"7","directionName":null,"speed":"72"},
{"device":"868120244912371","locateTime":"1649162059","locateType":"1","locateTypeName":"gps定位","lng":"108.764014","lat":"29.14729","address":null,"direction":"16","directionName":null,"speed":"77"},
{"device":"868120244912371","locateTime":"1649161097","locateType":"1","locateTypeName":"gps定位","lng":"108.80551","lat":"28.989105","address":null,"direction":"12","directionName":null,"speed":"70"},
{"device":"868120244912371","locateTime":"1649160142","locateType":"2","locateTypeName":"基站定位","lng":"108.7566319","lat":"28.8401904","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649159168","locateType":"1","locateTypeName":"gps定位","lng":"108.800727","lat":"28.695427","address":null,"direction":"335","directionName":null,"speed":"77"},
{"device":"868120244912371","locateTime":"1649158193","locateType":"1","locateTypeName":"gps定位","lng":"108.93068","lat":"28.621049","address":null,"direction":"335","directionName":null,"speed":"49"},
{"device":"868120244912371","locateTime":"1649157019","locateType":"1","locateTypeName":"gps定位","lng":"109.030079","lat":"28.471805","address":null,"direction":"25","directionName":null,"speed":"66"},
{"device":"868120244912371","locateTime":"1649156045","locateType":"1","locateTypeName":"gps定位","lng":"109.030182","lat":"28.305121","address":null,"direction":"332","directionName":null,"speed":"61"},
{"device":"868120244912371","locateTime":"1649155084","locateType":"1","locateTypeName":"gps定位","lng":"109.145991","lat":"28.187496","address":null,"direction":"298","directionName":null,"speed":"76"},
{"device":"868120244912371","locateTime":"1649154081","locateType":"1","locateTypeName":"gps定位","lng":"109.226163","lat":"28.053519","address":null,"direction":"12","directionName":null,"speed":"6"},
{"device":"868120244912371","locateTime":"1649151715","locateType":"1","locateTypeName":"gps定位","lng":"109.226086","lat":"28.053339","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649151246","locateType":"1","locateTypeName":"gps定位","lng":"109.228769","lat":"28.03509","address":null,"direction":"344","directionName":null,"speed":"67"},
{"device":"868120244912371","locateTime":"1649150259","locateType":"1","locateTypeName":"gps定位","lng":"109.30553","lat":"27.903956","address":null,"direction":"251","directionName":null,"speed":"77"},
{"device":"868120244912371","locateTime":"1649149297","locateType":"1","locateTypeName":"gps定位","lng":"109.486899","lat":"27.939069","address":null,"direction":"268","directionName":null,"speed":"71"},
{"device":"868120244912371","locateTime":"1649148344","locateType":"1","locateTypeName":"gps定位","lng":"109.653864","lat":"27.958617","address":null,"direction":"270","directionName":null,"speed":"79"},
{"device":"868120244912371","locateTime":"1649147387","locateType":"1","locateTypeName":"gps定位","lng":"109.762691","lat":"27.8413","address":null,"direction":"326","directionName":null,"speed":"79"},
{"device":"868120244912371","locateTime":"1649146435","locateType":"1","locateTypeName":"gps定位","lng":"109.868336","lat":"27.70426","address":null,"direction":"306","directionName":null,"speed":"82"},
{"device":"868120244912371","locateTime":"1649145492","locateType":"2","locateTypeName":"基站定位","lng":"109.9165872","lat":"27.5379571","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649143584","locateType":"1","locateTypeName":"gps定位","lng":"109.764456","lat":"27.225094","address":null,"direction":"61","directionName":null,"speed":"74"},
{"device":"868120244916596","locateTime":"1649135192","locateType":"1","locateTypeName":"gps定位","lng":"113.081509","lat":"23.157238","address":null,"direction":"0","directionName":null,"speed":"1"},
{"device":"868120244916596","locateTime":"1649136227","locateType":"2","locateTypeName":"基站定位","lng":"113.0797206","lat":"23.1562137","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649137211","locateType":"1","locateTypeName":"gps定位","lng":"113.081394","lat":"23.157705","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649138158","locateType":"2","locateTypeName":"基站定位","lng":"113.0797206","lat":"23.1562137","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649139165","locateType":"1","locateTypeName":"gps定位","lng":"113.08128","lat":"23.157715","address":null,"direction":"0","directionName":null,"speed":"2"},
{"device":"868120244916596","locateTime":"1649140112","locateType":"2","locateTypeName":"基站定位","lng":"113.0818772","lat":"23.1618812","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649141091","locateType":"1","locateTypeName":"gps定位","lng":"113.081181","lat":"23.157498","address":null,"direction":"9","directionName":null,"speed":"1"},
{"device":"868120244916596","locateTime":"1649142050","locateType":"1","locateTypeName":"gps定位","lng":"113.061958","lat":"23.155019","address":null,"direction":"309","directionName":null,"speed":"41"},
{"device":"868120244916596","locateTime":"1649143017","locateType":"1","locateTypeName":"gps定位","lng":"113.023145","lat":"23.20715","address":null,"direction":"251","directionName":null,"speed":"36"},
{"device":"868120244916596","locateTime":"1649143983","locateType":"1","locateTypeName":"gps定位","lng":"112.858743","lat":"23.258881","address":null,"direction":"293","directionName":null,"speed":"70"},
{"device":"868120244916596","locateTime":"1649144978","locateType":"1","locateTypeName":"gps定位","lng":"112.70398","lat":"23.377535","address":null,"direction":"260","directionName":null,"speed":"89"},
{"device":"868120244916596","locateTime":"1649145944","locateType":"1","locateTypeName":"gps定位","lng":"112.524728","lat":"23.440371","address":null,"direction":"302","directionName":null,"speed":"84"},
{"device":"868120244916596","locateTime":"1649146896","locateType":"1","locateTypeName":"gps定位","lng":"112.391034","lat":"23.560969","address":null,"direction":"303","directionName":null,"speed":"79"},
{"device":"868120244916596","locateTime":"1649147856","locateType":"1","locateTypeName":"gps定位","lng":"112.268731","lat":"23.681159","address":null,"direction":"1","directionName":null,"speed":"90"},
{"device":"868120244916596","locateTime":"1649148820","locateType":"1","locateTypeName":"gps定位","lng":"112.200787","lat":"23.825323","address":null,"direction":"299","directionName":null,"speed":"85"},
{"device":"868120244916596","locateTime":"1649149828","locateType":"1","locateTypeName":"gps定位","lng":"112.068704","lat":"23.960498","address":null,"direction":"267","directionName":null,"speed":"89"},
{"device":"868120244916596","locateTime":"1649150950","locateType":"1","locateTypeName":"gps定位","lng":"111.831005","lat":"23.999957","address":null,"direction":"289","directionName":null,"speed":"80"},
{"device":"868120244916596","locateTime":"1649152084","locateType":"2","locateTypeName":"基站定位","lng":"111.7027138","lat":"24.149751","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649153045","locateType":"1","locateTypeName":"gps定位","lng":"111.65281","lat":"24.297828","address":null,"direction":"332","directionName":null,"speed":"81"},
{"device":"868120244916596","locateTime":"1649153998","locateType":"1","locateTypeName":"gps定位","lng":"111.645491","lat":"24.350507","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649154947","locateType":"1","locateTypeName":"gps定位","lng":"111.645491","lat":"24.350367","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649155896","locateType":"1","locateTypeName":"gps定位","lng":"111.526417","lat":"24.362284","address":null,"direction":"264","directionName":null,"speed":"80"},
{"device":"868120244916596","locateTime":"1649156848","locateType":"1","locateTypeName":"gps定位","lng":"111.346858","lat":"24.37896","address":null,"direction":"292","directionName":null,"speed":"72"},
{"device":"868120244916596","locateTime":"1649157796","locateType":"1","locateTypeName":"gps定位","lng":"111.185195","lat":"24.394079","address":null,"direction":"298","directionName":null,"speed":"71"},
{"device":"868120244916596","locateTime":"1649158750","locateType":"1","locateTypeName":"gps定位","lng":"111.067759","lat":"24.497697","address":null,"direction":"311","directionName":null,"speed":"67"},
{"device":"868120244916596","locateTime":"1649159705","locateType":"1","locateTypeName":"gps定位","lng":"110.916513","lat":"24.581761","address":null,"direction":"340","directionName":null,"speed":"83"},
{"device":"868120244916596","locateTime":"1649160659","locateType":"1","locateTypeName":"gps定位","lng":"110.756155","lat":"24.647196","address":null,"direction":"277","directionName":null,"speed":"64"},
{"device":"868120244916596","locateTime":"1649161606","locateType":"1","locateTypeName":"gps定位","lng":"110.593585","lat":"24.673947","address":null,"direction":"296","directionName":null,"speed":"75"},
{"device":"868120244916596","locateTime":"1649162555","locateType":"1","locateTypeName":"gps定位","lng":"110.444426","lat":"24.726972","address":null,"direction":"330","directionName":null,"speed":"85"},
{"device":"868120244916596","locateTime":"1649163508","locateType":"1","locateTypeName":"gps定位","lng":"110.433409","lat":"24.750146","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649164457","locateType":"1","locateTypeName":"gps定位","lng":"110.433279","lat":"24.750333","address":null,"direction":"4","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649165409","locateType":"1","locateTypeName":"gps定位","lng":"110.433241","lat":"24.750401","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649166353","locateType":"1","locateTypeName":"gps定位","lng":"110.433295","lat":"24.750417","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649167300","locateType":"1","locateTypeName":"gps定位","lng":"110.433226","lat":"24.750441","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649168250","locateType":"1","locateTypeName":"gps定位","lng":"110.433211","lat":"24.750588","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649169194","locateType":"1","locateTypeName":"gps定位","lng":"110.433798","lat":"24.750873","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649170137","locateType":"1","locateTypeName":"gps定位","lng":"110.43331","lat":"24.750539","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649144528","locateType":"1","locateTypeName":"gps定位","lng":"109.900058","lat":"27.35373","address":null,"direction":"40","directionName":null,"speed":"90"},
{"device":"868120244912371","locateTime":"1649142630","locateType":"1","locateTypeName":"gps定位","lng":"109.724975","lat":"27.045498","address":null,"direction":"347","directionName":null,"speed":"88"},
{"device":"868120244912371","locateTime":"1649141660","locateType":"1","locateTypeName":"gps定位","lng":"109.753517","lat":"26.878826","address":null,"direction":"27","directionName":null,"speed":"78"},
{"device":"868120244912371","locateTime":"1649140715","locateType":"1","locateTypeName":"gps定位","lng":"109.730231","lat":"26.719569","address":null,"direction":"358","directionName":null,"speed":"68"},
{"device":"868120244912371","locateTime":"1649139771","locateType":"1","locateTypeName":"gps定位","lng":"109.719549","lat":"26.563718","address":null,"direction":"307","directionName":null,"speed":"25"},
{"device":"868120244912371","locateTime":"1649137727","locateType":"1","locateTypeName":"gps定位","lng":"109.721589","lat":"26.563574","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649137092","locateType":"2","locateTypeName":"基站定位","lng":"109.7490614","lat":"26.5335289","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649136120","locateType":"1","locateTypeName":"gps定位","lng":"109.889164","lat":"26.443979","address":null,"direction":"346","directionName":null,"speed":"80"},
{"device":"868120244912371","locateTime":"1649135176","locateType":"1","locateTypeName":"gps定位","lng":"109.891656","lat":"26.292283","address":null,"direction":"27","directionName":null,"speed":"69"},
{"device":"868120244912371","locateTime":"1649134225","locateType":"1","locateTypeName":"gps定位","lng":"109.8184","lat":"26.143946","address":null,"direction":"18","directionName":null,"speed":"78"},
{"device":"868120244912371","locateTime":"1649133280","locateType":"1","locateTypeName":"gps定位","lng":"109.777391","lat":"25.978477","address":null,"direction":"352","directionName":null,"speed":"54"},
{"device":"868120244912371","locateTime":"1649132311","locateType":"2","locateTypeName":"基站定位","lng":"109.7816523","lat":"25.8541854","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649131337","locateType":"1","locateTypeName":"gps定位","lng":"109.94516","lat":"25.78864","address":null,"direction":"15","directionName":null,"speed":"24"},
{"device":"868120244912371","locateTime":"1649130125","locateType":"1","locateTypeName":"gps定位","lng":"109.947553","lat":"25.787818","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649129815","locateType":"1","locateTypeName":"gps定位","lng":"109.947247","lat":"25.787886","address":null,"direction":"350","directionName":null,"speed":"7"},
{"device":"868120244912371","locateTime":"1649129687","locateType":"1","locateTypeName":"gps定位","lng":"109.94623","lat":"25.787654","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649129538","locateType":"1","locateTypeName":"gps定位","lng":"109.946299","lat":"25.787633","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649128586","locateType":"1","locateTypeName":"gps定位","lng":"110.064517","lat":"25.728015","address":null,"direction":"359","directionName":null,"speed":"95"},
{"device":"868120244912371","locateTime":"1649127642","locateType":"1","locateTypeName":"gps定位","lng":"110.054179","lat":"25.576817","address":null,"direction":"338","directionName":null,"speed":"42"},
{"device":"868120244912371","locateTime":"1649126687","locateType":"1","locateTypeName":"gps定位","lng":"110.096429","lat":"25.409785","address":null,"direction":"339","directionName":null,"speed":"65"},
{"device":"868120244912371","locateTime":"1649125733","locateType":"1","locateTypeName":"gps定位","lng":"110.141151","lat":"25.258895","address":null,"direction":"39","directionName":null,"speed":"78"},
{"device":"868120244912371","locateTime":"1649124778","locateType":"1","locateTypeName":"gps定位","lng":"110.222831","lat":"25.119274","address":null,"direction":"289","directionName":null,"speed":"87"},
{"device":"868120244912371","locateTime":"1649123830","locateType":"1","locateTypeName":"gps定位","lng":"110.320113","lat":"24.93589","address":null,"direction":"332","directionName":null,"speed":"89"},
{"device":"868120244912371","locateTime":"1649122879","locateType":"1","locateTypeName":"gps定位","lng":"110.419502","lat":"24.764908","address":null,"direction":"322","directionName":null,"speed":"84"},
{"device":"868120244912371","locateTime":"1649121936","locateType":"1","locateTypeName":"gps定位","lng":"110.578361","lat":"24.678578","address":null,"direction":"310","directionName":null,"speed":"70"},
{"device":"868120244912371","locateTime":"1649120985","locateType":"1","locateTypeName":"gps定位","lng":"110.775853","lat":"24.646219","address":null,"direction":"271","directionName":null,"speed":"86"},
{"device":"868120244912371","locateTime":"1649120015","locateType":"1","locateTypeName":"gps定位","lng":"110.955778","lat":"24.562885","address":null,"direction":"288","directionName":null,"speed":"96"},
{"device":"868120244912371","locateTime":"1649118827","locateType":"1","locateTypeName":"gps定位","lng":"111.15086","lat":"24.414721","address":null,"direction":"262","directionName":null,"speed":"76"},
{"device":"868120244912371","locateTime":"1649117863","locateType":"1","locateTypeName":"gps定位","lng":"111.340686","lat":"24.381092","address":null,"direction":"291","directionName":null,"speed":"90"},
{"device":"868120244912371","locateTime":"1649116892","locateType":"1","locateTypeName":"gps定位","lng":"111.539726","lat":"24.363732","address":null,"direction":"256","directionName":null,"speed":"86"},
{"device":"868120244912371","locateTime":"1649106564","locateType":"1","locateTypeName":"gps定位","lng":"111.645996","lat":"24.349567","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649095711","locateType":"1","locateTypeName":"gps定位","lng":"111.645996","lat":"24.349567","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649095531","locateType":"1","locateTypeName":"gps定位","lng":"111.645965","lat":"24.349556","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649094563","locateType":"1","locateTypeName":"gps定位","lng":"111.674656","lat":"24.219149","address":null,"direction":"325","directionName":null,"speed":"75"},
{"device":"868120244912371","locateTime":"1649093603","locateType":"1","locateTypeName":"gps定位","lng":"111.731685","lat":"24.058897","address":null,"direction":"323","directionName":null,"speed":"64"},
{"device":"868120244912371","locateTime":"1649092629","locateType":"1","locateTypeName":"gps定位","lng":"111.903908","lat":"23.985583","address":null,"direction":"322","directionName":null,"speed":"60"},
{"device":"868120244912371","locateTime":"1649091672","locateType":"1","locateTypeName":"gps定位","lng":"112.104012","lat":"23.959647","address":null,"direction":"261","directionName":null,"speed":"55"},
{"device":"868120244912371","locateTime":"1649090710","locateType":"1","locateTypeName":"gps定位","lng":"112.217388","lat":"23.814703","address":null,"direction":"291","directionName":null,"speed":"67"},
{"device":"868120244912371","locateTime":"1649089756","locateType":"1","locateTypeName":"gps定位","lng":"112.266562","lat":"23.659657","address":null,"direction":"336","directionName":null,"speed":"63"},
{"device":"868120244912371","locateTime":"1649088803","locateType":"1","locateTypeName":"gps定位","lng":"112.410067","lat":"23.552136","address":null,"direction":"291","directionName":null,"speed":"82"},
{"device":"868120244912371","locateTime":"1649166563","locateType":"1","locateTypeName":"gps定位","lng":"108.390249","lat":"29.476187","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649166056","locateType":"2","locateTypeName":"基站定位","lng":"108.4305344","lat":"29.4860522","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649165091","locateType":"2","locateTypeName":"基站定位","lng":"108.6156741","lat":"29.5052108","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649163981","locateType":"2","locateTypeName":"基站定位","lng":"108.7772938","lat":"29.4433176","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649163005","locateType":"1","locateTypeName":"gps定位","lng":"108.767138","lat":"29.303981","address":null,"direction":"7","directionName":null,"speed":"72"},
{"device":"868120244912371","locateTime":"1649162059","locateType":"1","locateTypeName":"gps定位","lng":"108.764014","lat":"29.14729","address":null,"direction":"16","directionName":null,"speed":"77"},
{"device":"868120244912371","locateTime":"1649161097","locateType":"1","locateTypeName":"gps定位","lng":"108.80551","lat":"28.989105","address":null,"direction":"12","directionName":null,"speed":"70"},
{"device":"868120244912371","locateTime":"1649160142","locateType":"2","locateTypeName":"基站定位","lng":"108.7566319","lat":"28.8401904","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649159168","locateType":"1","locateTypeName":"gps定位","lng":"108.800727","lat":"28.695427","address":null,"direction":"335","directionName":null,"speed":"77"},
{"device":"868120244912371","locateTime":"1649158193","locateType":"1","locateTypeName":"gps定位","lng":"108.93068","lat":"28.621049","address":null,"direction":"335","directionName":null,"speed":"49"},
{"device":"868120244912371","locateTime":"1649157019","locateType":"1","locateTypeName":"gps定位","lng":"109.030079","lat":"28.471805","address":null,"direction":"25","directionName":null,"speed":"66"},
{"device":"868120244912371","locateTime":"1649156045","locateType":"1","locateTypeName":"gps定位","lng":"109.030182","lat":"28.305121","address":null,"direction":"332","directionName":null,"speed":"61"},
{"device":"868120244912371","locateTime":"1649155084","locateType":"1","locateTypeName":"gps定位","lng":"109.145991","lat":"28.187496","address":null,"direction":"298","directionName":null,"speed":"76"},
{"device":"868120244912371","locateTime":"1649154081","locateType":"1","locateTypeName":"gps定位","lng":"109.226163","lat":"28.053519","address":null,"direction":"12","directionName":null,"speed":"6"},
{"device":"868120244912371","locateTime":"1649151715","locateType":"1","locateTypeName":"gps定位","lng":"109.226086","lat":"28.053339","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244912371","locateTime":"1649151246","locateType":"1","locateTypeName":"gps定位","lng":"109.228769","lat":"28.03509","address":null,"direction":"344","directionName":null,"speed":"67"},
{"device":"868120244912371","locateTime":"1649150259","locateType":"1","locateTypeName":"gps定位","lng":"109.30553","lat":"27.903956","address":null,"direction":"251","directionName":null,"speed":"77"},
{"device":"868120244912371","locateTime":"1649149297","locateType":"1","locateTypeName":"gps定位","lng":"109.486899","lat":"27.939069","address":null,"direction":"268","directionName":null,"speed":"71"},
{"device":"868120244912371","locateTime":"1649148344","locateType":"1","locateTypeName":"gps定位","lng":"109.653864","lat":"27.958617","address":null,"direction":"270","directionName":null,"speed":"79"},
{"device":"868120244912371","locateTime":"1649147387","locateType":"1","locateTypeName":"gps定位","lng":"109.762691","lat":"27.8413","address":null,"direction":"326","directionName":null,"speed":"79"},
{"device":"868120244912371","locateTime":"1649146435","locateType":"1","locateTypeName":"gps定位","lng":"109.868336","lat":"27.70426","address":null,"direction":"306","directionName":null,"speed":"82"},
{"device":"868120244912371","locateTime":"1649145492","locateType":"2","locateTypeName":"基站定位","lng":"109.9165872","lat":"27.5379571","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244912371","locateTime":"1649143584","locateType":"1","locateTypeName":"gps定位","lng":"109.764456","lat":"27.225094","address":null,"direction":"61","directionName":null,"speed":"74"},
{"device":"868120244916596","locateTime":"1649135192","locateType":"1","locateTypeName":"gps定位","lng":"113.081509","lat":"23.157238","address":null,"direction":"0","directionName":null,"speed":"1"},
{"device":"868120244916596","locateTime":"1649136227","locateType":"2","locateTypeName":"基站定位","lng":"113.0797206","lat":"23.1562137","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649137211","locateType":"1","locateTypeName":"gps定位","lng":"113.081394","lat":"23.157705","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649138158","locateType":"2","locateTypeName":"基站定位","lng":"113.0797206","lat":"23.1562137","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649139165","locateType":"1","locateTypeName":"gps定位","lng":"113.08128","lat":"23.157715","address":null,"direction":"0","directionName":null,"speed":"2"},
{"device":"868120244916596","locateTime":"1649140112","locateType":"2","locateTypeName":"基站定位","lng":"113.0818772","lat":"23.1618812","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649141091","locateType":"1","locateTypeName":"gps定位","lng":"113.081181","lat":"23.157498","address":null,"direction":"9","directionName":null,"speed":"1"},
{"device":"868120244916596","locateTime":"1649142050","locateType":"1","locateTypeName":"gps定位","lng":"113.061958","lat":"23.155019","address":null,"direction":"309","directionName":null,"speed":"41"},
{"device":"868120244916596","locateTime":"1649143017","locateType":"1","locateTypeName":"gps定位","lng":"113.023145","lat":"23.20715","address":null,"direction":"251","directionName":null,"speed":"36"},
{"device":"868120244916596","locateTime":"1649143983","locateType":"1","locateTypeName":"gps定位","lng":"112.858743","lat":"23.258881","address":null,"direction":"293","directionName":null,"speed":"70"},
{"device":"868120244916596","locateTime":"1649144978","locateType":"1","locateTypeName":"gps定位","lng":"112.70398","lat":"23.377535","address":null,"direction":"260","directionName":null,"speed":"89"},
{"device":"868120244916596","locateTime":"1649145944","locateType":"1","locateTypeName":"gps定位","lng":"112.524728","lat":"23.440371","address":null,"direction":"302","directionName":null,"speed":"84"},
{"device":"868120244916596","locateTime":"1649146896","locateType":"1","locateTypeName":"gps定位","lng":"112.391034","lat":"23.560969","address":null,"direction":"303","directionName":null,"speed":"79"},
{"device":"868120244916596","locateTime":"1649147856","locateType":"1","locateTypeName":"gps定位","lng":"112.268731","lat":"23.681159","address":null,"direction":"1","directionName":null,"speed":"90"},
{"device":"868120244916596","locateTime":"1649148820","locateType":"1","locateTypeName":"gps定位","lng":"112.200787","lat":"23.825323","address":null,"direction":"299","directionName":null,"speed":"85"},
{"device":"868120244916596","locateTime":"1649149828","locateType":"1","locateTypeName":"gps定位","lng":"112.068704","lat":"23.960498","address":null,"direction":"267","directionName":null,"speed":"89"},
{"device":"868120244916596","locateTime":"1649150950","locateType":"1","locateTypeName":"gps定位","lng":"111.831005","lat":"23.999957","address":null,"direction":"289","directionName":null,"speed":"80"},
{"device":"868120244916596","locateTime":"1649152084","locateType":"2","locateTypeName":"基站定位","lng":"111.7027138","lat":"24.149751","address":null,"direction":null,"directionName":null,"speed":null},
{"device":"868120244916596","locateTime":"1649153045","locateType":"1","locateTypeName":"gps定位","lng":"111.65281","lat":"24.297828","address":null,"direction":"332","directionName":null,"speed":"81"},
{"device":"868120244916596","locateTime":"1649153998","locateType":"1","locateTypeName":"gps定位","lng":"111.645491","lat":"24.350507","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649154947","locateType":"1","locateTypeName":"gps定位","lng":"111.645491","lat":"24.350367","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649155896","locateType":"1","locateTypeName":"gps定位","lng":"111.526417","lat":"24.362284","address":null,"direction":"264","directionName":null,"speed":"80"},
{"device":"868120244916596","locateTime":"1649156848","locateType":"1","locateTypeName":"gps定位","lng":"111.346858","lat":"24.37896","address":null,"direction":"292","directionName":null,"speed":"72"},
{"device":"868120244916596","locateTime":"1649157796","locateType":"1","locateTypeName":"gps定位","lng":"111.185195","lat":"24.394079","address":null,"direction":"298","directionName":null,"speed":"71"},
{"device":"868120244916596","locateTime":"1649158750","locateType":"1","locateTypeName":"gps定位","lng":"111.067759","lat":"24.497697","address":null,"direction":"311","directionName":null,"speed":"67"},
{"device":"868120244916596","locateTime":"1649159705","locateType":"1","locateTypeName":"gps定位","lng":"110.916513","lat":"24.581761","address":null,"direction":"340","directionName":null,"speed":"83"},
{"device":"868120244916596","locateTime":"1649160659","locateType":"1","locateTypeName":"gps定位","lng":"110.756155","lat":"24.647196","address":null,"direction":"277","directionName":null,"speed":"64"},
{"device":"868120244916596","locateTime":"1649161606","locateType":"1","locateTypeName":"gps定位","lng":"110.593585","lat":"24.673947","address":null,"direction":"296","directionName":null,"speed":"75"},
{"device":"868120244916596","locateTime":"1649162555","locateType":"1","locateTypeName":"gps定位","lng":"110.444426","lat":"24.726972","address":null,"direction":"330","directionName":null,"speed":"85"},
{"device":"868120244916596","locateTime":"1649163508","locateType":"1","locateTypeName":"gps定位","lng":"110.433409","lat":"24.750146","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649164457","locateType":"1","locateTypeName":"gps定位","lng":"110.433279","lat":"24.750333","address":null,"direction":"4","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649165409","locateType":"1","locateTypeName":"gps定位","lng":"110.433241","lat":"24.750401","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649166353","locateType":"1","locateTypeName":"gps定位","lng":"110.433295","lat":"24.750417","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649167300","locateType":"1","locateTypeName":"gps定位","lng":"110.433226","lat":"24.750441","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649168250","locateType":"1","locateTypeName":"gps定位","lng":"110.433211","lat":"24.750588","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649169194","locateType":"1","locateTypeName":"gps定位","lng":"110.433798","lat":"24.750873","address":null,"direction":"0","directionName":null,"speed":"0"},
{"device":"868120244916596","locateTime":"1649170137","locateType":"1","locateTypeName":"gps定位","lng":"110.43331","lat":"24.750539","address":null,"direction":"0","directionName":null,"speed":"0"},]

// 获取运单轨迹
export function getTrack(query) {
  if (debug) {
    return Promise.resolve({
      data: trackList
    })
  }
  return request({
    url: '/shipping_info/track',
    method: 'post',
    data: query
  })
}
